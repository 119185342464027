#color-code-popover {
  .color-circle {
    width: 1em;
    height: 1em;
    border-radius: 2em;
    aspect-ratio: 1 / 1;
  }
}

#cohort-users-button {
  .height-30px {
    height: 30px;
  }
}
